@mixin color_1 {
  color: #c0dfd9;
}
@mixin color_2 {
  color: #e9ece6;
}

@mixin color_3 {
  color: #bccbc9;
}
@mixin color_4 {
  color: #aab8b6;
}

@mixin color_5 {
  color: #545b5a;
}

@mixin bodyBgcolor {
  background-color: #111111;
}
