* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: timesNewRomanNormal;
  src: url("/src//fonts/times\ new\ roman.ttf");
}

body {
  font-family: timesNewRomanNormal, sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.mv10 {
  margin: 10px 0px;
}
.mv20 {
  margin: 20px 0px;
}
.mv30 {
  margin: 30px 0px;
}

.mv40 {
  margin: 40px 0px;
}

.mv50 {
  margin: 50px 0px;
}

.mh10 {
  margin: 0px 10px;
}
.mh20 {
  margin: 0px 20px;
}
.mh30 {
  margin: 0px 30px;
}

.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt50 {
  margin-top: 30px;
}

.mt80 {
  margin-top: 80px;
}

.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb50 {
  margin-bottom: 50px;
}
.mt60 {
  margin-top: 50px;
}

@media only screen and (min-width: 999px) {
  body {
    /* min-width: 1200px; */
  }

  .mv10 {
    margin: 5px 0px;
  }
  .mv20 {
    margin: 10px 0px;
  }
  .mv30 {
    margin: 15px 0px;
  }

  .mv40 {
    margin: 20px 0px;
  }

  .mv50 {
    margin: 25px 0px;
  }
}
