@use "/src/styles/_mixins.scss";

.App {
  @include mixins.bodyBgcolor;

}
.forLoading{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 17px;
  background-color: #f3f3f3;
  color: black;
  .loadingIcon{
    border: 5px solid black;
    border-radius: 50%;
    border-top: 6px solid lightgray;
    width: 55px;
    height: 55px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* justify-content: center; */
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
// .slick-slider {
//   /* background-color: aquamarine; */
//   // padding-left: 20px;
// }

// .slick-prev {
//   z-index: 1;
//   /* background-color: red !important; */
// }
// .slick-next {
//   z-index: 1;
//   /* background-color: red !important; */
// }

// .slick-dots li button:before {
//   color: rgb(179, 178, 178) !important;
//   font-size: 10px !important;
//   margin-top: 10px;
// }
// .slick-dots button {
//   /* background-color: blue !important; */
// }

// .slick-dots li.slick-active button:before {
//   color: white !important;
//   /* font-size: 12px !important; */
// }
